<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">平台设定</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form ref="form" :model="form" label-width="160px" style="max-width: 750px;">
				<el-card shadow="never" class="mt-24 mt-3">
					<div slot="header">
						<span>商家设置</span>
					</div>
					<el-row>
						<el-form-item label="默认logo">
							<app-attachment :multiple="false" :max="1" v-model="form.merchant.logo">
								<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
							</app-attachment>
							<app-gallery :url="form.merchant.logo" :show-delete="true"
								@deleted="form.merchant.logo = ''" width="80px" height="80px"></app-gallery>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24 mt-3">
					<el-row class="mt-3">
						<el-form-item label="平台公告">
							<el-input type="textarea" v-model="form.store.notice"></el-input>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24 mt-3">
					<el-row class="mt-3">
						<el-form-item label="平台免责声明">
							<el-input type="textarea" v-model="form.store.disclaimer"></el-input>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24 mt-3">
					<el-row class="mt-3">
						<el-form-item label="腾讯地图KEY">
							<el-input v-model="form.store.map_key"></el-input>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24 mt-3">
					<div slot="header">
						<span>上传设置</span>
					</div>
					<el-row>
						<el-form-item label="默认上传方式">
							<el-radio v-model="form.storage.default" value="local" label="local">
								本地（不推荐）
							</el-radio>
							<el-radio v-model="form.storage.default" value="qiniu" label="qiniu">
								七牛云存储
							</el-radio>
							</el-radio-group>
						</el-form-item>
						<template v-if="form.storage.default == 'qiniu'">
							<el-form-item label="存储空间名称 Bucket">
								<el-input v-model="form.storage.engine.qiniu.bucket"></el-input>
							</el-form-item>
							<el-form-item label="ACCESS_KEY AK">
								<el-input v-model="form.storage.engine.qiniu.access_key"></el-input>
							</el-form-item>
							<el-form-item label="SECRET_KEY SK">
								<el-input v-model="form.storage.engine.qiniu.secret_key"></el-input>
							</el-form-item>
							<el-form-item label="空间域名 Domain">
								<el-input v-model="form.storage.engine.qiniu.domain"></el-input>
							</el-form-item>
						</template>
					</el-row>
				</el-card>
				<el-form-item class="mt-2">
					<el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				loading: false,
				form: {
					storage: {
						default: 'local',
						engine: {
							local: {},
							qiniu: {
								bucket: '',
								access_key: '',
								secret_key: '',
								domain: 'http://'
							},
							aliyun: {
								bucket: '',
								access_key_id: '',
								access_key_secret: '',
								domain: 'http://'
							},
							qcloud: {
								bucket: '',
								region: '',
								secret_id: '',
								secret_key: '',
								domain: 'http://'
							}
						}
					},
					store: {
						disclaimer: "",
						notice: "",
						map_key: "",
					},
					merchant: {
						logo: '',
					}
				},
			}
		},
		created() {
			this.getSetting();
		},
		methods: {
			getSetting() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/setting/setting',
					},
					method: 'get',
				}).then(res => {
					this.form.store = res.data.data.store
					this.form.storage = res.data.data.storage
					this.form.merchant = res.data.data.merchant
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},

			// 提交
			submit() {
				this.loading = true;
				this.axios({
					token: true,
					params: {
						s: 'store/setting/setting',
					},
					data: {
						setting: this.form
					},
					method: 'post',
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						// 跳转后台首页
						this.$router.push({
							name: 'setting/setting'
						})
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
				})
			},
		},
	}
</script>

<style>

</style>
